<template>
  <div class="history-wrapper">
    <div class="history-box">
      <div class="live-conetnt">
        <div class="content-title">
          <h3>
            <div>
              <img src="../../assets/images/live-broadcast/live-icon.png" alt="" />
            </div>
            视频浏览记录
          </h3>
          <a-button type="primary" @click="goBack">返回</a-button>
        </div>
        <div class="line"></div>
        <Screen :isShow="true" :placeholder="'搜索视频名称'" @getList="getList" />
        <div class="list-player">
          <watch-item v-if="Boolean(list.length)" :list="list" :isShow="false" />
          <a-empty v-else>
            <template #description>
              <p>暂无浏览</p>
            </template>
          </a-empty>
        </div>
        <Pagination :params="params" :total="total" />
      </div>
    </div>
  </div>
</template>

<script>
import WatchItem from '@/components/WatchItem.vue';
import api from '@/services';
import { useRouter } from 'vue-router';
import Pagination from '@/components/Pagination.vue';
import Screen from '@/components/Screen.vue';
import { ref, provide, onMounted } from 'vue';
import { fieldName, video_attribute, typeText } from '@/store/fieldConfiguration';
import { getLabelList } from '@/store/publicFunction.js';
export default {
  components: {
    WatchItem,
    Pagination,
    Screen,
  },
  setup() {
    // 获取标签筛选列表
    const labelList = ref(null);
    provide('labelList', labelList);
    const setLabelList = async () => {
      labelList.value = await getLabelList('getVideoLabelList');
    };
    setLabelList();

    const params = ref({
      startOnlineTime: null,
      endOnlineTime: null,
      labelList: null,
      title: '',
      type: null,
      curPage: 1,
      pageSize: 10,
    });
    const total = ref(0);
    const list = ref([]);
    provide('params', params);
    const getList = async () => {
      try {
        const { data } = await api.getRecentlyWatchedList(params.value);
        list.value = data?.records.map(item => {
          console.log(item);
          item.lastOnlineTime = `浏览时间 ${item.lastOnlineTime}`;
          item.type = typeText[item.type];
          let paramsJson = JSON.stringify(item);
          video_attribute.forEach((oldKey, index) => {
            const reg = oldKey;
            paramsJson = paramsJson.replace(reg, fieldName[index]);
          });
          return JSON.parse(paramsJson);
        });
        total.value = data?.total || 0;
      } catch (error) {
        console.error(error);
      }
    };
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    }
    onMounted(() => {
      getList();
    });
    return {
      getList,
      goBack,
      total,
      params,
      list,
    };
  },
};
</script>

<style lang="less" scoped>
@import '../../assets/less/public.less';
.history-wrapper {
  .live-video();
  .history-box {
    padding-top: 30px;
    background-color: #f6f6f6;

    .live-conetnt {
      width: 1400px;
      margin: 0 auto;
      border-radius: 10px;
      overflow: hidden;
      background-color: #fff;
      min-height: 90vh;
      .content-title {
        display: flex;
        padding: 30px 20px;
        justify-content: space-between;
        align-items: center;
        div {
          img {
            vertical-align: top;
            width: unset;
            height: unset;
          }
          margin-right: 8px;
        }
        h3 {
          display: flex;
          color: #333333;
          font-size: 16px;
        }
      }
      .line {
        height: 1px;
        background-color: #e5e5e5;
        width: 1400px;
      }
      .search-box {
        padding: 0 20px;
        width: 100%;
        top: 0;
        margin: 30px auto;
      }
      .list-player {
        width: 100%;
        padding: 0 20px;
        .attention-and-watch {
          ::v-deep(.single) {
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}
</style>
